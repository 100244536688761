<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  margin: 0;
}
* {
  font-family: 'Inter Variable', sans-serif;
}
</style>
